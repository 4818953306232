import api from '../axios-middleware';
import {
  IGenerateAdditionalTicketsRequest,
  IGenerateAdditionalTicketsResponse,
  IGenerateTicketRequest,
  IGenerateTicketResponse,
  IPriceCheckRequest,
  IPriceCheckResponse,
  ICheckTransactionResponse,
  IRegisterPayment,
  IRegisterPaymentResponse,
  IPaymentStatus,
  IPayTheTicket,
  IPayTheTicketResponse,
  IsendBackPayment,
  IsendBackPaymentResponse,
  IDownloadTicketRequest,
  IDownloadTicketResponse,
  ICancelTicketRequest,
  ITicketList,
  ITicketListResponse,
  IReturnTicket,
  IReturnTicketResponse,
  IChangePassengerDataRequest,
  ITicketAsPdfResponse,
  IIdList,
  IIdResponseList,
  IGenerateAdditionalFeeRequest,
  IGenerateAdditionalFeeResponse,
} from './interfaces';
import {checkRefreshAndReturnJwt, renewToken} from 'Utils/auth';
import {ICheckDiscountsRequest, ICheckDiscountsResponse} from './interfaces';
import {IErrorsList} from 'api/error-interface';
import {env} from 'env';
import {IGuestTicket} from 'api/auth/interfaces';
import i18n from 'i18n';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getStationsWithoutTransfers = (stations: Array<any>) => stations.filter((item) => item?.pociagNr !== 0);

const methods = {
  getPriceForTrip: async (obj: IPriceCheckRequest) => {
    const {odcinki, ...rest} = obj;

    return await api.post<IPriceCheckResponse>('/server/public/endpoint/Sprzedaz', {
      ...rest,
      odcinki: getStationsWithoutTransfers(odcinki),
      urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
      metoda: 'sprawdzCene',
    });
  },
  checkDiscounts: async (obj: ICheckDiscountsRequest) => {
    return await api.post<ICheckDiscountsResponse>('/server/public/endpoint/Sprzedaz', {
      ...obj,
      urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
      metoda: 'sprawdzZnizki',
    });
  },
  generateTicket: async (obj: IGenerateTicketRequest) => {
    const token = await checkRefreshAndReturnJwt();
    const {odcinki, ...rest} = obj;
    return await api.postExt<IGenerateTicketResponse>(
      '/server/private/endpoint/Sprzedaz',
      {
        ...rest,
        odcinki: getStationsWithoutTransfers(odcinki),
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'wygenerujBilet',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  generateUniversalTicket: async (obj: IGenerateTicketRequest) => {
    const token = await checkRefreshAndReturnJwt();
    const {odcinki, ...rest} = obj;
    return await api.postExt<IGenerateTicketResponse>(
      '/server/private/endpoint/Sprzedaz',
      {
        ...rest,
        odcinki: getStationsWithoutTransfers(odcinki),
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'wygenerujBiletUniwersalny',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  generateAdditionalTickets: async (obj: IGenerateAdditionalTicketsRequest) => {
    const token = await checkRefreshAndReturnJwt();
    return await api.postExt<IGenerateAdditionalTicketsResponse>(
      '/server/private/endpoint/Sprzedaz',
      {
        ...obj,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'wygenerujBiletDodatkowy',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  getTransactionStatus: async (transaction: number[]) => {
    const token = await checkRefreshAndReturnJwt();
    return await api.postExt<ICheckTransactionResponse>(
      '/server/private/endpoint/Sprzedaz',
      {
        transakcjeNr: transaction,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'pobierzStatusTransakcji',
        zrodlo: 0,
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },

  registerPayment: async (obj: IRegisterPayment) => {
    const token = await checkRefreshAndReturnJwt();
    return await api.postExt<IRegisterPaymentResponse>(
      'p24/payment/register/EIC_2',
      {
        ...obj,
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },

  checkPaymentStatus: async (id: number) => {
    const token = await checkRefreshAndReturnJwt();
    return await api.getExt<IPaymentStatus>(`p24/payment/status/${id}`, {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    });
  },

  payTheTicket: async (obj: IPayTheTicket) => {
    const token = await checkRefreshAndReturnJwt();
    return await api.postExt<IPayTheTicketResponse>(
      '/server/private/endpoint/Sprzedaz',
      {
        ...obj,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'oplacBilet',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },

  sendBackPayment: async (obj: IsendBackPayment) => {
    const token = await checkRefreshAndReturnJwt();
    return await api.postExt<IsendBackPaymentResponse>(
      '/server/private/endpoint/Sprzedaz',
      {
        ...obj,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'zwrocPlatnosc',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },

  downloadTicket: async (obj: IDownloadTicketRequest) => {
    const token = await checkRefreshAndReturnJwt();
    return await api.postExt<IDownloadTicketResponse>(
      '/server/private/endpoint/Sprzedaz',
      {
        ...obj,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'pobierzBilet',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },

  downloadGuestTicket: async (obj: IDownloadTicketRequest, ticket: IGuestTicket) => {
    const token = await renewToken(ticket);
    return await api.postExt<IDownloadTicketResponse>(
      '/server/private/endpoint/Sprzedaz',
      {
        ...obj,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'pobierzBilet',
        biletSeria: ticket.series,
        biletNr: ticket.number,
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },

  cancelTicket: async (obj: ICancelTicketRequest) => {
    const token = await checkRefreshAndReturnJwt();
    return await api.postExt<IErrorsList>(
      '/server/private/endpoint/Sprzedaz',
      {
        ...obj,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'anulujBilet',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  getTicketList: async (obj: ITicketList) => {
    const token = await checkRefreshAndReturnJwt();
    return await api.postExt<ITicketListResponse>(
      '/server/private/endpoint/Sprzedaz',
      {
        ...obj,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'pobierzListeBiletow',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  getTicketIdent: async (obj: IIdList) => {
    const token = await checkRefreshAndReturnJwt();
    return await api.postExt<IIdResponseList>(
      '/server/private/endpoint/Sprzedaz',
      {
        ...obj,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'pobierzIdentyfikatoryBiletow',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  returnTicket: async (obj: IReturnTicket) => {
    const token = await checkRefreshAndReturnJwt();
    return await api.postExt<IReturnTicketResponse>(
      '/server/private/endpoint/Sprzedaz',
      {
        ...obj,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'zwrocBilet',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  changePassengerData: async (obj: IChangePassengerDataRequest) => {
    const token = await checkRefreshAndReturnJwt();
    return await api.postExt<IErrorsList>(
      '/server/private/endpoint/Sprzedaz',
      {
        ...obj,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'zmienDanePodroznego',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  changePassengerDataOnUnpaidTicket: async (obj: IChangePassengerDataRequest) => {
    const token = await checkRefreshAndReturnJwt();
    return await api.postExt<IErrorsList>(
      '/server/private/endpoint/Sprzedaz',
      {
        ...obj,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'zmienDanePodroznegoDlaBiletowNieoplaconych',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  exchangeTicket: async (exchangedTransNr: number, transNr: number) => {
    const token = await checkRefreshAndReturnJwt();
    return await api.postExt<IReturnTicketResponse>(
      '/server/private/endpoint/Sprzedaz',
      {
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'zwrocBilet',
        transakcjaNr: exchangedTransNr,
        biletSeria: null,
        biletNr: null,
        powodKod: 0,
        wymianaTransakcjaNr: transNr,
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  downloadTicketAsPdf: async (ticketSerial: string, ticketNr: number) => {
    const token = await checkRefreshAndReturnJwt();
    return await api.postExt<ITicketAsPdfResponse>(
      '/server/private/endpoint/Sprzedaz',
      {
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'pobierzBiletPDF',
        biletJezyk: i18n.language.toUpperCase(),
        biletSeria: ticketSerial,
        biletNr: ticketNr,
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  generateAdditionalFee: async (obj: IGenerateAdditionalFeeRequest) => {
    const token = await checkRefreshAndReturnJwt();
    return await api.postExt<IGenerateAdditionalFeeResponse>(
      '/server/private/endpoint/Sprzedaz',
      {
        ...obj,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'wygenerujOplateDodatkowa',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
};

export default methods;
