import {StyledTheme} from './types';
import {text} from './text';

export const contrastTheme: StyledTheme = {
  color: {
    main: {
      default: '#464b53',
      light: '#464b53',
    },
    primary: {
      default: '#f7f7f7',
      dark: '#0071bc',
    },
    secondary: {
      default: '#fdf251',
    },
    tertiary: {
      default: '#464b53',
    },
    highlight: {
      default: '#fdf251',
    },
    accent: {
      default: '#fdf251',
      alt: '#fdf251',
      light: '#f6f6f6',
    },
    info: {
      default: '#fdf251',
    },
    inactive: {
      default: '#777777',
    },
    light: {
      primary: '#ffffff',
      secondary: '#f6f6f6',
    },
    dark: {
      default: '#000000',
    },
    success: {
      default: '#43a00e',
    },
    warning: {
      default: '#fdf251',
    },
    error: {
      default: '#F5AF00',
    },
    gold: {
      default: '#E0AC1B',
    },
    eco: {
      default: '#4c7b31',
    },
    gray: {
      default: '#b7b7b7',
      light: '#464b53',
      dark: '#fdf251',
    },
    text: {
      primary: '#f6f6f6',
      secondary: '#fdf251',
      info: '#000',
      eco: '#f6f6f6',
      disabled: '#B7B7B7',
    },
    shadow: {
      primary: 'rgba(192, 192, 192, 0.5)',
      secondary: 'rgba(28, 28, 28, 0.8)',
    },
    background: {
      primary: '#1c1c1c',
      secondary: '#464b53',
      tertiary: '#e0f3fa',
      info: '#fdf251',
      light: '#1c1c1c',
      tag: '#1c1c1c',
      disabled: '#dfe3e8',
    },
    border: {
      primary: '#ffffff',
      secondary: '#fdf251',
      hover: '#817575',
      accent: '#14A0DB',
    },
    container: {
      primary: '#ffd20010',
      accent: '#ee003910',
    },
    components: {
      button: {
        gray: '#dfe3e8',
        text: '#000000',
        inactive: '#f6f6f6',
        hover: '#dfd220',
      },
      badge: {
        light: '#CDEAF7',
      },
      tooltip: {
        text: '#ffffff',
        background: 'rgba(0, 0, 0, .75)',
      },
      select: {
        active: 'rgba(253, 242, 81, 0.4)',
      },
      scrollbar: {
        default: '#fdf251',
      },
      loaderbar: {
        default: 'rgba(223, 227, 232, 0.3)',
      },
    },
  },
  shadow: {
    primary: '0px 2px 4px rgba(192, 192, 192, 0.5)',
    secondary: '0px 2px 4px rgba(224, 224, 224, 0.2)',
    tertiary: '0px 2px 4px 0px rgba(192, 192, 192, 0.5)',
    footer: '0 -2px 4px 0 rgba(224, 224, 224, 0.2)',
  },
  text,
};
