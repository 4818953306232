import {FC, Fragment, useState, useMemo, useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {checkIfGuest, formatPrice, getFullDate, returnStationByCode} from 'Utils/commonFunctions';
import {UserTicketsContext} from 'Utils/UserTicketsContext';
import {useData} from 'Utils/DataContex';
import {useStyledThemeState} from 'common/theme';
import {useAuth} from 'Utils/AuthContext';
import {useMyTicketsDispatch} from 'common/pages/UserProfile/MyTickets/hooks/useTickets';
import {isAdditionalTicketKind, isPrimaryTicketKind} from 'common/pages/UserProfile/MyTickets';
import {useInvalidateMyTicketsQueries} from 'common/api/myTickets';
import {useMyTicketDispatch} from 'common/pages/UserProfile/MyTickets/hooks/useTicket';

import saleApi from 'api/sprzedaz/repository';
import seazonalApi from 'api/biletyOkresowe/repository';

import ConfirmButton from 'components/common/ConfirmButton';
import OutlineButton from 'components/common/OutlineButton';

import style from './ReturnTicket.module.scss';
import OrangeX from 'assets/orangeX.svg';
import ContrastX from 'assets/contrastX.svg';
import QRcode from 'assets/qr-code-icon.svg';
import QRcodeContrast from 'assets/qr-code-icon-contrast.svg';
import {useTranslation} from 'react-i18next';
import {IReturnTicket, IDownloadTicketResponseSingleTicket} from 'api/sprzedaz/interfaces';
import {IError, IErrorDesc} from 'api/error-interface';
import {isReservationFromSeasonalTicket} from 'components/pages/UserProfile/common/utils';
import {isForeignConnection} from 'common/utils';
import Loader from 'components/common/Loader/Loader';
import {Box} from '@mui/material';
import {Text} from 'common/components/base/Text';

interface Props {
  isMobile?: boolean;
}

const ReturnTicket: FC<Props> = ({isMobile}) => {
  const {t, i18n} = useTranslation();
  const {userData: myUserData} = useAuth();
  const {isContrastTheme} = useStyledThemeState();
  const {
    returnedTicketData,
    setReturnedTicketData,
    actualTicketsIdent,
    setActualTicketsIdent,
    actualTicketsFull,
    setActualTicketsFull,
  } = useContext(UserTicketsContext);
  const {stationsList} = useData();
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [refundAmount, setRefundAmount] = useState(0);
  useEffect(() => {
    success && setStep(2);
  }, [success]);
  const {
    invalidateSingleTickets,
    invalidateSeasonalTickets,
    invalidateReturnedTickets,
    invalidateMyTicket,
    invalidateReservationList,
  } = useInvalidateMyTicketsQueries();
  const {setTicketVariant} = useMyTicketsDispatch();
  const isReturnedTicketAdditional = useMemo(
    () => isAdditionalTicketKind(returnedTicketData?.ticket.bilety[0].biletRodzaj as number),
    [returnedTicketData],
  );
  const primaryReturnedTickets =
    returnedTicketData?.ticket.bilety.filter((el) => isPrimaryTicketKind(el.biletRodzaj)) ?? [];
  const {setTicketDetails} = useMyTicketDispatch();

  const isForeign = useMemo(
    () =>
      returnedTicketData?.ticket.bilety.some((ticket: IDownloadTicketResponseSingleTicket): boolean =>
        ticket.odcinki.some(({stacjaDoKod, stacjaOdKod}) => isForeignConnection(stacjaOdKod, stacjaDoKod)),
      ),
    [returnedTicketData?.ticket.bilety],
  );

  const sumUpCash = () => {
    let subTotal = 0;
    returnedTicketData?.ticket.bilety.forEach(
      (el) =>
        !el.zwrotOpis &&
        (subTotal = subTotal + (el.biletRodzaj === 31 ? el?.cenaCalkowita : el.cenaBilet + el?.cenaRezerwacja)),
    );
    return (subTotal * 0.85) / 100;
  };

  const getReturnValue = (price: number) => ((price * 0.85) / 100).toFixed(2);

  const setGuestStorageData = () => {
    if (checkIfGuest()) sessionStorage.setItem('eic2MyTickets', JSON.stringify({hasReturnedTicket: true}));
  };

  const returnTicket = async () => {
    if (isMobile && !isReturnedTicketAdditional) setTicketVariant('RETURNED');
    const makeObj = (tic: IDownloadTicketResponseSingleTicket) => {
      return {
        transakcjaNr: tic.transakcjaNr,
        biletSeria:
          tic.biletRodzaj !== 1 && tic.biletRodzaj !== 2 && tic.biletRodzaj !== 10
            ? returnedTicketData?.ticketSr
            : null,
        biletNr: tic.biletRodzaj !== 1 && tic.biletRodzaj !== 2 && tic.biletRodzaj !== 10 ? tic.biletNr : null,
        powodKod: 0,
        wymianaTransakcjaNr: 0,
      } as IReturnTicket;
    };
    const handleReturnTicketError = (errors: IError[]) => {
      const messagesArray: string[] = [];

      errors.forEach((error: IError) => {
        messagesArray.push(
          error.opisy.find((description: IErrorDesc) => {
            return description.jezyk.toLocaleLowerCase() === i18n.language;
          })?.komunikat || error.opisy[0].komunikat,
        );
      });
      setErrorMessages(messagesArray.length ? messagesArray : [t('24051')]);
    };
    try {
      const promises = returnedTicketData?.ticket.bilety.map(async (el) => {
        if (el.zwrotData) {
          return;
        }
        if (!returnedTicketData?.seazonalTicket) {
          setLoading(true);
          const {bledy, kwotaZwrotu, zwrotRozpoczety} = await saleApi.returnTicket(makeObj(el));
          if (bledy.length > 0 || zwrotRozpoczety === 1) {
            handleReturnTicketError(bledy);
            setLoading(false);
          } else {
            setRefundAmount(kwotaZwrotu);
            setGuestStorageData();
            setSuccess(true);
            setLoading(false);
          }
        } else {
          const {bledy} = await seazonalApi.returnSezonalTicket(el.transakcjaNr);
          if (bledy.length > 0) {
            handleReturnTicketError(bledy);
          } else {
            setSuccess(true);
          }
        }
      });
      promises && (await Promise.all(promises));
      if (
        returnedTicketData?.ticket.bilety[0].biletRodzaj === 2 ||
        returnedTicketData?.ticket.bilety[0].biletRodzaj === 1 ||
        returnedTicketData?.ticket.bilety[0].biletRodzaj === 10
      ) {
        const newActualArr = actualTicketsFull?.filter(
          (el) => el.ticketInfo.biletNr !== returnedTicketData?.ticket.bilety[0].biletNr,
        );
        setActualTicketsFull(newActualArr ? [...newActualArr] : []);
        setActualTicketsIdent({
          ...actualTicketsIdent,
          paginacja: {
            obecnaStrona: actualTicketsIdent?.paginacja?.obecnaStrona,
            wszystkichStron: actualTicketsIdent?.paginacja?.wszystkichStron,
            wszystkichWynikow: (actualTicketsIdent?.paginacja?.wszystkichWynikow as number) - 1,
            wynikowNaStrone: actualTicketsIdent?.paginacja?.wynikowNaStrone,
          },
        });
      } else {
        const newActualArr = actualTicketsFull?.map((el) => {
          const searchedTicket = el.ticketDetail.bilety.find(
            (el2) => el2.biletNr === returnedTicketData?.ticket.bilety[0].biletNr,
          );
          if (searchedTicket) {
            searchedTicket.zwrotOpis = t('29153');
            return {
              searchDate: el.searchDate,
              ticketDetail: {
                bilety: [
                  ...el.ticketDetail.bilety.filter(
                    (el2) => el2.biletNr !== returnedTicketData?.ticket.bilety[0].biletNr,
                  ),
                  searchedTicket,
                ],
              },
              ticketInfo: {
                ...el.ticketInfo,
              },
            };
          } else return el;
        });
        setActualTicketsFull(newActualArr ? [...newActualArr] : []);
      }
    } catch (e) {
      console.log(e);
    } finally {
      invalidateMyTicket(
        returnedTicketData?.mainTicketIdentifier.transakcjaNr,
        returnedTicketData?.mainTicketIdentifier.biletSeria,
        returnedTicketData?.mainTicketIdentifier.biletNr,
      );
      if (isReservationFromSeasonalTicket(returnedTicketData?.mainTicketIdentifier.typBiletu)) {
        invalidateReservationList(
          returnedTicketData?.mainTicketIdentifier.biletPodstawowySeria,
          returnedTicketData?.mainTicketIdentifier.biletPodstawowyNr,
        );
      }
      if (!isReturnedTicketAdditional) {
        if (returnedTicketData?.seazonalTicket) {
          invalidateSeasonalTickets();
          invalidateSingleTickets();
        } else {
          invalidateSingleTickets();
        }
        invalidateReturnedTickets();
      }
    }
  };

  const getAdditionalTickets = () => {
    if (returnedTicketData?.ticket) {
      return returnedTicketData?.ticket.bilety.filter(
        (el) => el.biletRodzaj !== 2 && el.biletRodzaj !== 1 && el.biletRodzaj !== 10 && !el.zwrotOpis,
      );
    } else return [];
  };

  const getTicketType = (type: number) => {
    if (type === 1 || type === 2) {
      return t('13001');
    } else if (type === 20) {
      return t('29151');
    } else if (type === 21 || type === 22) {
      return t('29152');
    } else if (type === 23) {
      return t('19007');
    }
  };

  const closeWithReload = () => {
    setReturnedTicketData(null);
    if (isReturnedTicketAdditional) {
      invalidateMyTicket(
        returnedTicketData?.mainTicketIdentifier.transakcjaNr,
        returnedTicketData?.mainTicketIdentifier.biletSeria,
        returnedTicketData?.mainTicketIdentifier.biletNr,
      );
      return;
    }
    errorMessages ? setTicketVariant('SINGLE') : setTicketVariant('RETURNED');
    if (isMobile && !isReturnedTicketAdditional) {
      setTicketDetails(undefined, undefined);
      navigate('/profil/biletyzwrocone');
    }
  };

  const closeButton = () => {
    if (isReturnedTicketAdditional) {
      setReturnedTicketData(null);
      return;
    }
    if (step !== 1 && !isReturnedTicketAdditional) {
      errorMessages ? setTicketVariant('SINGLE') : setTicketVariant('RETURNED');
    }
    setReturnedTicketData(null);
  };

  const isCouchette = (): boolean => {
    const places = returnedTicketData?.ticket?.bilety[0]?.odcinki[0]?.miejsca;
    const seatCode = (!!places && places[0]?.miejsceTypKod) || 0;
    return !!seatCode && (seatCode === 13 || seatCode === 14);
  };

  const isSleeper = (): boolean => {
    const places = returnedTicketData?.ticket?.bilety[0]?.odcinki[0]?.miejsca;
    const seatCode = (!!places && places[0]?.miejsceTypKod) || 0;
    return (
      !!seatCode &&
      (seatCode === 101 ||
        seatCode === 102 ||
        seatCode === 103 ||
        seatCode === 170101 ||
        seatCode === 170102 ||
        seatCode === 170103)
    );
  };
  const checkReturnedTicketData = returnedTicketData?.ticket.bilety.length === 0 ? t('29148') : t('29149');
  const printErrorMessages = (messages: string[]) => {
    return (
      <ul className={style.return_ticket__errors}>
        {messages.map((message, index) => (
          <li className={style.return_ticket__error} key={index}>
            {message}
          </li>
        ))}
      </ul>
    );
  };
  return (
    <div className={style.return_ticket__modal}>
      <div className={style.return_ticket__modal_box}>
        <header className={style.return_ticket__modal_box_header}>
          <h1>{step === 1 ? t('29150') : checkReturnedTicketData}</h1>
          <button
            aria-label={t('29002')}
            onClick={
              step === 1
                ? () => {
                    setReturnedTicketData(null);
                  }
                : () => closeWithReload()
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') closeButton();
            }}
          >
            <img src={isContrastTheme ? ContrastX : OrangeX} alt={t('24006')} />
          </button>
        </header>
        {!errorMessages && !loading && (
          <div className={style.return_ticket__ticket_data}>
            <img src={isContrastTheme ? QRcodeContrast : QRcode} alt={t('29137')} />
            <div>
              {(isReturnedTicketAdditional || (!isReturnedTicketAdditional && returnedTicketData?.seazonalTicket)) && (
                <p className={style.return_ticket__text_regular}>
                  {t('29298')}: {`${returnedTicketData?.ticket.bilety[0].ofertaNazwa}`}
                </p>
              )}

              {!!returnedTicketData?.ticket.bilety[0]?.odcinki[0].stacjaOdKod && (
                <p className={style.return_ticket__text_regular}>
                  {returnStationByCode(
                    returnedTicketData?.ticket.bilety[0]?.odcinki[0].stacjaOdKod as number,
                    stationsList,
                  )}
                </p>
              )}

              {!!returnedTicketData?.ticket.bilety[0]?.odcinki[returnedTicketData?.ticket.bilety[0]?.odcinki.length - 1]
                .stacjaDoKod && (
                <p className={style.return_ticket__text_regular}>
                  {returnStationByCode(
                    returnedTicketData?.ticket.bilety[0]?.odcinki[
                      returnedTicketData?.ticket.bilety[0]?.odcinki.length - 1
                    ].stacjaDoKod as number,
                    stationsList,
                  )}
                </p>
              )}

              {returnedTicketData?.seazonalTicket ? (
                <p className={style.return_ticket__text_regular}>
                  {getFullDate(returnedTicketData?.ticket.bilety[0]?.waznoscBiletuOd as string)} -{' '}
                  {getFullDate(returnedTicketData?.ticket.bilety[0]?.waznoscBiletuDo as string)}
                </p>
              ) : (
                <p className={style.return_ticket__text_regular}>
                  {getFullDate(returnedTicketData?.ticket.bilety[0]?.odcinki[0].wyjazdData as string)}
                </p>
              )}

              {primaryReturnedTickets &&
                primaryReturnedTickets.map(({biletSeria, biletNr}) => (
                  <p key={biletSeria + biletNr} className={style.return_ticket__text_regular}>
                    {biletSeria + biletNr}
                  </p>
                ))}

              {returnedTicketData?.ticket.bilety.map(
                (ticket) =>
                  !ticket.zwrotOpis &&
                  isAdditionalTicketKind(ticket.biletRodzaj) && (
                    <p key={`${ticket.biletNr}-${ticket.biletSeria}`} className={style.return_ticket__text_medium}>
                      {ticket.biletSeria + ticket.biletNr}
                    </p>
                  ),
              )}
            </div>
          </div>
        )}
        {step === 1 && (
          <>
            {loading ? (
              <div className={style.return_ticket__loader_container}>
                <Loader />
                <Box mt={5}>
                  <Text size={14} weight={700} palette="text.primary">
                    {t('25021')}
                  </Text>
                </Box>
              </div>
            ) : (
              <>
                <div className={style.return_ticket__info}>
                  {!errorMessages && (
                    <>
                      <div>
                        <h2 className={style.return_ticket__text_blue}>{t('29001')}</h2>
                        <p className={style.return_ticket__text_regular}>{t('29138')}</p>
                      </div>
                      <div>
                        <h2 className={style.return_ticket__text_blue}>{t('29141')}</h2>
                        {!isForeign && (
                          <p className={style.return_ticket__text_regular}>
                            {t('29139')}
                            {returnedTicketData?.ticket?.bilety?.length &&
                              returnedTicketData?.ticket?.bilety?.length > 1 &&
                              getAdditionalTickets().length !== 0 &&
                              !isReturnedTicketAdditional && <span> {t('29140')}</span>}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  {!!returnedTicketData?.ticket.bilety[0].fakturaNr && (
                    <div>
                      <h2 className={style.return_ticket__text_blue}>{t('26004')}</h2>
                      <p className={style.return_ticket__text_regular}>{t('29147')}</p>
                    </div>
                  )}
                </div>
                <h2 className={style.return_ticket__bottom_txt}>
                  {errorMessages ? printErrorMessages(errorMessages) : t('29146')}
                </h2>
              </>
            )}
          </>
        )}
        {step === 2 && (
          <div className={style.return_ticket__info}>
            <div>
              <h2 className={style.return_ticket__text_blue}>{t('29141')}</h2>
              {isForeign ? (
                <Box mb={2}>
                  <p className={style.return_ticket__text_regular}>{t('29142')}</p>
                  <p className={style.return_ticket__text_regular}>{formatPrice(t, refundAmount, true)}</p>
                </Box>
              ) : (
                <div className={style.return_ticket__column_wrapper}>
                  <div className={style.return_ticket__column}>
                    <p className={style.return_ticket__text_regular}>{t('29142')}</p>
                    {returnedTicketData?.ticket.bilety.map(
                      (ticket) =>
                        !ticket.zwrotOpis && (
                          <Fragment key={`${ticket.biletNr}-${ticket.biletSeria}`}>
                            <p className={style.return_ticket__text_medium}>{getTicketType(ticket.biletRodzaj)}</p>
                            {isSleeper() && !!ticket?.cenaRezerwacja && (
                              <p className={style.return_ticket__text_regular}>{t('22062')}</p>
                            )}
                            {isCouchette() && !!ticket?.cenaRezerwacja && (
                              <p className={style.return_ticket__text_regular}>{t('22060')}</p>
                            )}
                          </Fragment>
                        ),
                    )}
                    <p className={style.return_ticket__text_regular}>{t('29143')}</p>
                  </div>
                  <div className={style.return_ticket__column}>
                    {returnedTicketData?.ticket.bilety.map(
                      (ticket) =>
                        !ticket.zwrotOpis && (
                          <Fragment key={`${ticket.biletNr}-${ticket.biletSeria}`}>
                            <p className={style.return_ticket__text_medium}>
                              {getReturnValue(ticket.cenaBilet).toString().replace('.', ',')} {t('24086')}
                            </p>
                            {(isSleeper() || isCouchette()) && !!ticket?.cenaRezerwacja && (
                              <p className={style.return_ticket__text_regular}>
                                {formatPrice(t, ticket?.cenaRezerwacja * 0.85 || 0)}
                              </p>
                            )}
                          </Fragment>
                        ),
                    )}
                    <p className={style.return_ticket__text_regular}>
                      {sumUpCash().toFixed(2).toString().replace('.', ',')} {t('24086')}
                    </p>
                  </div>
                </div>
              )}
              <p className={style.return_ticket__text_slime}>{new Date().toLocaleString()}</p>
            </div>
            {!!returnedTicketData?.ticket.bilety[0].fakturaNr && (
              <div>
                <h2 className={style.return_ticket__text_blue}>{t('26004')}</h2>
                <p className={style.return_ticket__text_regular}>
                  {t('29144')} <br /> {myUserData?.contactEmail}
                </p>
              </div>
            )}
          </div>
        )}
        {!loading && (
          <div className={`${style.return_ticket__btn_box} ${step === 1 ? style.btn_100 : style.btn_50}`}>
            {!errorMessages && step === 1 && (
              <OutlineButton
                text={t('22006')}
                path={() => {
                  setReturnedTicketData(null);
                }}
              />
            )}

            <ConfirmButton
              text={!errorMessages && step === 1 ? t('29145') : t('29002')}
              execute={!errorMessages && step === 1 ? returnTicket : closeWithReload}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReturnTicket;
