import {useQueries} from '@tanstack/react-query';
import {STALE_TIME} from './consts';
import {
  getAdditionalFees,
  getCommercialAttributes,
  getConfiguration,
  getDiscounts,
  getForeignDiscounts,
  getOffers,
  getPlaceKinds,
  getPlacePosition,
  getPlaceTypes,
  getRailwayCompanies,
  getSeasonalOffers,
  getSeasonalOffersKinds,
  getShopDiscounts,
  getStations,
  getTrainCategories,
  getPassengerForeignDiscounts,
} from './services';
import {keyBy} from 'lodash';

export const useFetchAllLibrary = (shouldFetch: boolean) => {
  const combinedQueries = useQueries({
    queries: [
      {
        queryKey: ['configuration'],
        queryFn: getConfiguration,
        staleTime: STALE_TIME,
        enabled: shouldFetch,
      },
      {
        queryKey: ['stations'],
        queryFn: getStations,
        staleTime: STALE_TIME,
        enabled: shouldFetch,
      },
      {
        queryKey: ['trainCategories'],
        queryFn: getTrainCategories,
        staleTime: STALE_TIME,
        enabled: shouldFetch,
      },
      {
        queryKey: ['placeKinds'],
        queryFn: getPlaceKinds,
        staleTime: STALE_TIME,
        enabled: shouldFetch,
      },
      {
        queryKey: ['placeTypes'],
        queryFn: getPlaceTypes,
        staleTime: STALE_TIME,
        enabled: shouldFetch,
      },
      {
        queryKey: ['placePosition'],
        queryFn: getPlacePosition,
        staleTime: STALE_TIME,
        enabled: shouldFetch,
      },
      {
        queryKey: ['railwayCompanies'],
        queryFn: getRailwayCompanies,
        staleTime: STALE_TIME,
        enabled: shouldFetch,
      },
      {
        queryKey: ['discounts'],
        queryFn: getDiscounts,
        staleTime: STALE_TIME,
        enabled: shouldFetch,
      },
      {
        queryKey: ['foreignDiscounts'],
        queryFn: getForeignDiscounts,
        staleTime: STALE_TIME,
        enabled: shouldFetch,
      },
      {
        queryKey: ['shopDiscounts'],
        queryFn: getShopDiscounts,
        staleTime: STALE_TIME,
        enabled: shouldFetch,
      },
      {
        queryKey: ['commercialAttributes'],
        queryFn: getCommercialAttributes,
        staleTime: STALE_TIME,
        enabled: shouldFetch,
      },
      {
        queryKey: ['offers'],
        queryFn: getOffers,
        staleTime: STALE_TIME,
        enabled: shouldFetch,
      },
      {
        queryKey: ['seasonalOffers'],
        queryFn: getSeasonalOffers,
        staleTime: STALE_TIME,
        enabled: shouldFetch,
      },
      {
        queryKey: ['seasonalOffersKinds'],
        queryFn: getSeasonalOffersKinds,
        staleTime: STALE_TIME,
        enabled: shouldFetch,
      },
      {
        queryKey: ['additionalFees'],
        queryFn: getAdditionalFees,
        staleTime: STALE_TIME,
        enabled: shouldFetch,
      },
      {
        queryKey: ['foreignPassengersDiscounts'],
        queryFn: getPassengerForeignDiscounts,
        staleTime: STALE_TIME,
        enabled: shouldFetch,
      },
    ],
  });

  return {
    data: shouldFetch
      ? {
          configuration: combinedQueries[0].data,
          stationsList: combinedQueries[1].data?.stacje,
          stationListMap: keyBy(combinedQueries[1].data?.stacje, (s) => {
            //TODO: do zmiany kiedy wyjaśni się kwestia unikalności kodów
            return s.kod ? s.kod : s.kodEPA; // Do zmiany
          }),
          trainCategories: combinedQueries[2].data?.kategoriePociagow,
          placeKinds: combinedQueries[3].data?.rodzajeMiejsc,
          placeTypes: combinedQueries[4].data?.typyMiejsc,
          placePosition: combinedQueries[5].data?.usytuowania,
          railwayCompanies: combinedQueries[6].data?.spolkiKolejowe,
          discounts: combinedQueries[7].data?.znizki,
          foreignDiscounts: combinedQueries[8].data?.znizki,
          shopDiscounts: combinedQueries[9].data?.znizkiZakupowe,
          commercialAttributes: combinedQueries[10].data?.atrybutyHandlowe,
          offers: combinedQueries[11].data?.oferty,
          seasonalOffers: combinedQueries[12].data?.ofertyOkresowe,
          seasonalOffersKinds: combinedQueries[13].data?.ofertyOkresoweRodzaje,
          additionalFees: combinedQueries[14].data?.oplatyDodatkowe,
          foreignPassengersDiscounts: combinedQueries[15].data?.znizki,
        }
      : undefined,
    isLoading: combinedQueries.some((query) => query.isLoading),
    isError: combinedQueries.some((query) => query.isError),
  };
};
