/* eslint-disable @typescript-eslint/no-unused-vars */
import {FC, ReactNode} from 'react';
import S from './ErrorYellow.module.scss';
import moment from 'moment';
import {IConnectionSearchResponse} from 'api/pociagi/interfaces';

import useSearchLogic from 'components/pages/PurchasePath/useSearchLogic';
import {useTranslation} from 'react-i18next';

interface IErrorYellow {
  displayText?: string;
  errorsTextArr?: Array<ReactNode>;
  presentDay?: string;
  searchResultsData?: IConnectionSearchResponse;
  handlePrevDayConnections?: () => void;
  handleNextDayConnections?: () => void;
  disableNextDayConnectionsAfterDate?: string;
  handleClose?: () => void;
  header?: string;
}

/**
 * Used for E2E tests.
 */
const TAG = 'ErrorYellow';

const ErrorYellow: FC<IErrorYellow> = ({
  displayText,
  errorsTextArr,
  presentDay,
  searchResultsData,
  handlePrevDayConnections,
  handleNextDayConnections,
  disableNextDayConnectionsAfterDate,
  handleClose,
  header,
}) => {
  const {returnDaysDiff, sortListByDeparture} = useSearchLogic();
  const {t} = useTranslation();
  const forthBackEarlierDay = (day: string) => {
    const sortedList = searchResultsData ? sortListByDeparture(searchResultsData.polaczenia) : [];
    const duration = returnDaysDiff(day, new Date().toString());

    if (duration <= 0) {
      return (
        <div className={`${S.errorYellow__container_column}`}>
          <span className={`${S.errorYellow__container_text}`}>
            {t('27001', {
              date: moment(presentDay).format('DD.MM.yyyy'),
              hour: ' ' + moment(sortedList[0]?.dataWyjazdu).format('HH:mm'),
            }).toString()}
            .
          </span>
        </div>
      );
    }

    return (
      <div className={`${S.errorYellow__container_column}`}>
        <span className={`${S.errorYellow__container_text}`}>
          {t('27002', {
            date: moment(presentDay).format('DD.MM.yyyy'),
            time: moment(sortedList[0]?.dataWyjazdu).format('HH:mm'),
          }).toString()}
        </span>
        <button
          className={`${S.errorYellow__container_text} ${S.errorYellow__container_text_pointer}`}
          onClick={handlePrevDayConnections}
          tabIndex={0}
        >
          <u>{t('27003', {date: moment(presentDay).subtract(1, 'days').format('DD.MM.yyyy')}).toString()}.</u>
        </button>
      </div>
    );
  };

  const forthBackLaterDay = (day: string) => {
    const sortedList = searchResultsData ? sortListByDeparture(searchResultsData.polaczenia) : [];
    const duration = returnDaysDiff(day, new Date().toString());
    const disableNextDay = disableNextDayConnectionsAfterDate
      ? returnDaysDiff(day, disableNextDayConnectionsAfterDate) >= 0
      : false;

    if (duration >= 30 || disableNextDay) {
      return (
        <div className={`${S.errorYellow__container_column}`}>
          <span className={`${S.errorYellow__container_text}`}>
            {t('27004', {
              date: moment(presentDay).format('DD.MM.yyyy'),
              time: moment(sortedList[sortedList.length - 1]?.dataWyjazdu).format('HH:mm'),
            }).toString()}
          </span>
        </div>
      );
    }

    return (
      <div className={`${S.errorYellow__container_column}`}>
        <span className={`${S.errorYellow__container_text}`}>
          {t('27005', {
            date: moment(presentDay).format('DD.MM.yyyy'),
            time: moment(sortedList[sortedList.length - 1]?.dataWyjazdu).format('HH:mm'),
          }).toString()}
        </span>
        <button
          data-testid={`${TAG}-get-next-day-connections`}
          className={`${S.errorYellow__container_text} ${S.errorYellow__container_text_pointer}`}
          onClick={handleNextDayConnections}
        >
          <u>{t('27006', {date: moment(presentDay).add(1, 'days').format('DD.MM.yyyy')}).toString()}.</u>
        </button>
      </div>
    );
  };

  if (presentDay && handlePrevDayConnections && searchResultsData) return forthBackEarlierDay(presentDay);

  if (presentDay && handleNextDayConnections && searchResultsData) return forthBackLaterDay(presentDay);

  const returnArray = (errorsArray: ReactNode[]) => {
    return errorsArray.map((item, index) => {
      return (
        <span key={index} className={`${S.errorYellow__container_text}`}>
          {item}
        </span>
      );
    });
  };

  const returnText = () => {
    return (
      <span className={`${S.errorYellow__container_text}`}>{displayText ? t(displayText) : `${t('27009')}!`}</span>
    );
  };

  return (
    <div data-testid={TAG} className={`${S.errorYellow__container}`}>
      <div className={`${S.errorYellow__container_columnFlex}`}>
        {header && <span className={S.errorYellow__container_text}>{header}</span>}
        {errorsTextArr ? returnArray(errorsTextArr) : returnText()}
      </div>
    </div>
  );
};

export default ErrorYellow;
