import {StyledTheme} from './types';
import {text} from './text';

export const defaultTheme: StyledTheme = {
  color: {
    main: {
      default: '#142458',
      light: '#2c3a69',
    },
    primary: {
      default: '#14a0db',
      dark: '#0071bc',
    },
    secondary: {
      default: '#14a0db',
    },
    tertiary: {
      default: '#14a0db',
    },
    highlight: {
      default: '#142458',
    },
    accent: {
      default: '#fa6a2e',
      alt: '#ffffff',
      light: '#fa6a2e',
    },
    info: {
      default: '#1D94D3',
    },
    inactive: {
      default: '#b7b7b7',
    },
    light: {
      primary: '#ffffff',
      secondary: '#f6f6f6',
    },
    dark: {
      default: '#000000',
    },
    success: {
      default: '#43a00e',
    },
    warning: {
      default: '#ffd200',
    },
    error: {
      default: '#ee0039',
    },
    gold: {
      default: '#E0AC1B',
    },
    eco: {
      default: '#4c7b31',
    },
    gray: {
      default: '#b7b7b7',
      light: '#f4f6f8',
      dark: '#696969',
    },
    text: {
      primary: '#142458',
      secondary: '#14a0db',
      info: '#fff',
      eco: '#43a00e',
      disabled: '#B7B7B7',
    },
    shadow: {
      primary: 'rgba(224, 224, 224, 0.5)',
      secondary: 'rgba(20, 36, 88, 0.4)',
    },
    background: {
      primary: '#ffffff',
      secondary: '#ffffff',
      tertiary: '#e0f3fa',
      info: '#1D94D3',
      light: 'rgba(212, 204, 204, 0.2)',
      tag: 'rgba(20, 160, 219, 0.2)',
      disabled: '#dfe3e8',
    },
    border: {
      primary: '#d4cccc',
      secondary: '#b7b7b7',
      hover: '#696969',
      accent: '#14A0DB',
    },
    container: {
      primary: '#14a0db10',
      accent: '#fa6a2e10',
    },
    components: {
      button: {
        gray: '#dfe3e8',
        text: '#142458',
        inactive: '#b7b7b7',
        hover: '#FF5B17',
      },
      badge: {
        light: '#CDEAF7',
      },
      tooltip: {
        text: '#ffffff',
        background: 'rgba(0, 0, 0, .75)',
      },
      select: {
        active: 'rgba(0, 0, 0, 0.04)',
      },
      scrollbar: {
        default: '#d4cccc',
      },
      loaderbar: {
        default: 'rgba(223, 227, 232, 0.3)',
      },
    },
  },
  shadow: {
    primary: '12px 11px 11px -9px rgba(192, 192, 192, 1)',
    secondary: '0 5px 4px 0 rgba(224, 224, 224, 0.5)',
    tertiary: '0px 2px 4px 0px rgba(192, 192, 192, 0.50)',
    footer: '0 -5px 4px 0 rgba(224, 224, 224, 0.5)',
  },
  text,
};
