export const PLACE_TYPE_CODES = {
  ANY: 0,
  COMPARTMENT: 1,
  WITHOUT_COMPARTMENT: 2,
  CHILD_UP_TO_6: 3,
  LIMITED_MOBILITY: 4,
  TABLE: 5,
  WHEELCHAIR: 6,
  THREE_SEATER_COUCHETTE: 12,
  FOUR_SEATER_COUCHETTE: 13,
  SIX_PEOPLE_COUCHETTE: 14,
  MANAGER: 21,
  BIKE: 24,
  WITH_CHILD: 50,
  WITH_DISABILITY: 51,
  SILENCE_ZONE: 52,
  CAREGIVER: 53,
  COMFORT_ZONE_2: 91,
  SINGLE_BED: 101,
  DOUBLE_BED: 102,
  TRIPLE_BED: 103,
  SINGLE_DELUXE_BED: 170101,
  DOUBLE_DELUXE_BED: 170102,
  TRIPLE_DELUXE_BED: 170103,
} as const;
