import {FC, Fragment, useCallback, useContext, useMemo, useState} from 'react';

import ICalendarLink from 'react-icalendar-link';
import {useNavigate} from 'react-router-dom';
import {
  downloadPdf,
  findEVACode,
  isInvoiceIssuable,
  returnStationByCode,
  makeTimeOfTravel,
  getFullDate,
  returnCarrierLogo,
  getFullTimeFromDate,
  checkIfGuest,
  isSeasonalTicketReturnable,
  getAdditionalTicketsFromPrimaryTicket,
} from 'Utils/commonFunctions';
import {useData} from 'Utils/DataContex';
import useMyTickets, {FullTicket} from '../useMyTickets';
import ConfirmButton from 'components/common/ConfirmButton';
import MyTicketsSingleTicketOpenMobileTripPart from './MyTicketsSingleTicketOpenMobileTripPart';
import MyTicketSingleTicketOpenDetails from './MyTicketSingleTicketOpenDetails';
import MyTicketSingleTicketOpenAddTicket from './MyTicketSingleTicketOpenAddTicket';
import ReturnTicketMobileFlow from '../../ReturnTicketMobileFlow/ReturnTicketMobileFlow';
import ChangePassengerName from '../ChangePassengerName/ChangePassengerName';
import MyDataModal from '../../MyData/MyDataModal';
import InvoiceInProgressModal from 'components/common/Invoice/InvoiceInProgressModal';
import {profileMenuElements} from 'components/pages/UserProfile/userProfileMenu';

import {addTripToCalendar} from 'Utils/addTripToCalendar';
import {useStyledThemeState} from 'common/theme';

import style from './MyTicketsSingleTicketOpenMobile.module.scss';
import ContrastX from 'assets/contrastX.svg';
import WhiteX from 'assets/whiteX.svg';

import Google from 'assets/google.svg';
import Clock from 'assets/clock_white.svg';
import ClockContrast from 'assets/clockContrast.svg';
import TicketSVG from 'assets/ticket.svg';

import {ISingleId} from 'api/sprzedaz/interfaces';
import {handleInvoiceDownload} from 'components/common/Invoice/invoiceHelpers';
import ExchangeTicketModal from 'components/pages/PurchasePath/TicketsExchange/ExchangeTicketModal';
import MyTicketsRouteModal from '../MyTicketsRouteModal/MyTicketsRouteModal';
import {getAvailableCarriers, getCarrierLogo} from 'components/pages/SeasonalTicket/common/utils';

import {useTranslation} from 'react-i18next';
import RemoveTicketModal from '../RemoveTicketModal/RemoveTicketModal';
import createProfileLinkWithParams from '../../BuyerProfile/helpers/createProfileLinkWithParams';
import {isReservationFromSeasonalTicket} from 'components/pages/UserProfile/common/utils';
import {
  getStationByCode,
  isForeignConnection,
  isSeasonalOfferCode,
  isTicketManuallyAdded,
  isTicketSourceEIC1,
} from 'common/utils';
import {MyTicket, ticketKinds} from 'common/api/myTickets';
import {SEASONAL_OFFER_CODES} from 'common/consts';
import {OFFER_CODES} from 'common/consts';
import {ReservationContext} from 'Utils/ReservationContext';
import {useInvalidateMyTicketsQueries} from 'common/api/myTickets';
import {useIsTooLateToReturn} from './useIsTooLateToReturn';

interface Props {
  allPrimaryTickets?: MyTicket[];
  exit: () => void;
  identifier: ISingleId;
  singleTicket: MyTicket[];
  ticketToReturn?: MyTicket[];
  history: number;
  makeQuery: (e: FullTicket, e2?: boolean) => string;
  isSeasonal?: boolean;
}

const MyTicketsSingleTicketOpenMobile: FC<Props> = ({
  allPrimaryTickets, //TODO This should be removed in the future, so do not use it anymore
  exit,
  ticketToReturn,
  singleTicket, // First ticket in array is always primary ticket, next ones are additional tickets, this is old code solution should be refactored
  identifier,
  history,
  makeQuery,
  isSeasonal,
}) => {
  const {t} = useTranslation();

  const {stationsList, seasonalOffers} = useData();
  const {checkIfTrainStarts} = useMyTickets();

  const [primaryAndAdditionalTickets, setPrimaryAndAdditionalTickets] = useState(singleTicket);
  const {theme, isContrastTheme} = useStyledThemeState();
  const [openReturnTicket, setOpenReturnTicket] = useState(false);
  const [openExchangeTicket, setOpenExchangeTicket] = useState<boolean>(false);
  const [openChangePassName, setOpenChangePassName] = useState(false);
  const [isRouteModalVisible, setIsRouteModalVisible] = useState<boolean>(false);
  const [showChangedName, setShowChangedName] = useState<boolean>(false);
  const [isInvoiceInProgress, setIsInvoiceInProgress] = useState<boolean>(false);
  const {setDownloadedTicketData} = useContext(ReservationContext);
  const [showRemoveTicketModal, setShowRemoveTicketModal] = useState<boolean>(false);
  const [invoiceError, setInvoiceError] = useState<string>('');

  const navigate = useNavigate();
  const [primaryTicket, ...additionalTickets] = primaryAndAdditionalTickets;
  const {
    fakturaNr,
    fakturaSeria,
    odcinki,
    biletSeria,
    biletNr,
    sprzedazData,
    kod2D,
    kodKontrolny,
    zwrotOpis,
    zwrotData,
    ofertaNazwa,
    ofertaKod,
    waznoscBiletuOd,
    waznoscBiletuDo,
    podroznyNazwa,
    biletZrodloKod,
    biletRodzaj,
    biletPodstawowyNr,
    biletPodstawowySeria,
    transakcjaNr,
    liczbaBagazy,
    liczbaPsow,
    liczbaRowerow,
    cenaPsy,
    cenaBagaze,
    cenaRowery,
    czyMozliwyZwrot,
    mozliwaZmianaDanychPodroznego,
  } = primaryTicket;
  const hasInvoice: boolean = !!fakturaNr.length && !!fakturaSeria.length;
  const lastRoute = odcinki[odcinki.length - 1];
  const firstRoute = odcinki[0];
  const departure = getStationByCode(firstRoute.stacjaOdKod, stationsList);
  const destination = getStationByCode(lastRoute.stacjaDoKod, stationsList);
  const isForeign = isForeignConnection(departure?.kodEVA || 0, destination?.kodEVA || 0);
  const isManuallyAdded = isTicketManuallyAdded(biletZrodloKod, biletSeria);
  const isIntercityCard = ofertaKod === SEASONAL_OFFER_CODES.INTERCITY_CARD;
  const isInternationalEPA = biletRodzaj === ticketKinds.INTERNATIONAL_EPA;
  const isReturned = !!primaryTicket?.zwrotData;
  const stationFrom = useMemo(
    () => returnStationByCode(firstRoute.stacjaOdKod, stationsList),
    [firstRoute, stationsList],
  );

  const stationFromCode = useMemo(
    () => returnStationByCode(lastRoute.stacjaDoKod, stationsList),
    [lastRoute.stacjaDoKod, stationsList],
  );
  const calendarEventDescription = useMemo(
    () =>
      `PKP Intercity z ${stationFrom} do ${stationFromCode}, ${getFullDate(
        firstRoute.wyjazdData,
      )} ${getFullTimeFromDate(firstRoute.wyjazdData)} - ${getFullDate(
        lastRoute.przyjazdData,
      )} ${getFullTimeFromDate(lastRoute.przyjazdData)}, ${t('29009')} ${biletSeria}${biletNr}`,

    [stationFrom, stationFromCode, firstRoute],
  );
  const calendarEventFileName = `PKP_Intercity_${firstRoute.stacjaOdKod}_${lastRoute.stacjaDoKod}_${getFullDate(
    firstRoute.wyjazdData,
  )}.ics`;
  const calendarEvent = {
    title: `podroz_z_${stationFrom}_do_${stationFromCode}`,
    description: calendarEventDescription,
    startTime: firstRoute.wyjazdData,
    endTime: lastRoute.przyjazdData,
    location: stationFrom.toString(),
  };

  const numberWordsArray = ['Pierwszy', 'Drugi', 'Trzeci', 'Czwarty', 'Piąty', 'Szósty', 'Siódmy'];

  const routeData = {
    dataWyjazdu: firstRoute.wyjazdData.replace(' ', 'T'),
    stacjaWyjazdu: findEVACode(Number(firstRoute.stacjaOdKod), stationsList),
    stacjaPrzyjazdu: findEVACode(Number(firstRoute.stacjaDoKod), stationsList),
    numerPociagu: Number(firstRoute.pociagNr),
  };

  const availableCarriers = getAvailableCarriers(ofertaKod, seasonalOffers) ?? [];
  const seasonalCarriersIconsToRender = (
    <div className={style.carriersIconsContainer}>
      {availableCarriers.map((carrier) => (
        <Fragment key={carrier}>{getCarrierLogo(carrier, isContrastTheme)}</Fragment>
      ))}
    </div>
  );
  const isInternationalEPAWithAdditionalTicket =
    biletRodzaj === ticketKinds.INTERNATIONAL_EPA && (liczbaBagazy > 0 || liczbaPsow > 0 || liczbaRowerow > 0);

  const internationalEPAAdditionalTickets = isInternationalEPAWithAdditionalTicket
    ? getAdditionalTicketsFromPrimaryTicket(liczbaBagazy, cenaBagaze, liczbaPsow, cenaPsy, liczbaRowerow, cenaRowery)
    : [];

  const goToOldVersion = () => {
    window.location.replace('https://bilet.intercity.pl/bezpieczne_logowanie.jsp?typ=1');
  };
  const generateRoute = (): JSX.Element => (
    <div className={`${style.mobile_ticket__box}`}>
      <p className={`${style.text_small} ${style.tileHeader}`}>{t('29325')}</p>
      <div className={`${style.mobile_ticket__flex_row_large_gap}`}>
        <p className={style.text_blue}>{t('29327')}</p>
        <p className={style.text_small}>
          {firstRoute.stacjaOdKod ? returnStationByCode(firstRoute.stacjaOdKod, stationsList) : t('29329')}
        </p>
      </div>
      <div className={`${style.mobile_ticket__flex_row_medium_gap}`}>
        <p className={style.text_blue}>{t('29328')}</p>
        <p className={style.text_small}>
          {firstRoute.stacjaDoKod ? returnStationByCode(firstRoute.stacjaDoKod, stationsList) : t('29329')}
        </p>
      </div>
      <div className={`${style.ticketValidInTrains}`}>
        <p className={style.text_small}>{t('29326')}</p>
        <div className={style.carriersIconsContainer}>
          {isSeasonal ? seasonalCarriersIconsToRender : returnCarrierLogo(firstRoute.pociagKategoriaNazwa, theme)}
        </div>
      </div>
      <div className={`${style.mobile_ticket__flex_row_small_gap}`}>
        <img src={TicketSVG} alt={t('11010')} />
        <p className={style.text_small}>
          {firstRoute.klasa} {t('22012')}
        </p>
      </div>
    </div>
  );

  const checkInvoiceIssuable = !isTicketSourceEIC1(biletSeria) ? (
    isInvoiceIssuable(sprzedazData) ? (
      <span className={style.mobile_ticket__btns_bottom_border}>
        <MyDataModal
          title={t('29248')}
          inputName="issueInvoice"
          ticketDetails={primaryAndAdditionalTickets}
          customOpenBtn={<button className={style.mobile_ticket__btns_box_btn}>{t('26045')}</button>}
          handleInvoiceIssue={(error) => {
            setInvoiceError(error);
            setIsInvoiceInProgress(true);
          }}
        />
      </span>
    ) : (
      <span className={style.mobile_ticket__btns_bottom_border}>
        <button className={style.disabledBtn} disabled>
          {t('26054')}
        </button>
      </span>
    )
  ) : null;

  const handleCreateProfileClick = useCallback(() => {
    if (!allPrimaryTickets) return;
    const {odcinki} = allPrimaryTickets[0];
    const lastTrain = allPrimaryTickets[allPrimaryTickets.length - 1];
    const link = createProfileLinkWithParams({
      stationFrom: odcinki[0].stacjaOdKod,
      stationTo: lastTrain.odcinki[0].stacjaDoKod,
    });
    navigate(`/profil/profilezakupowe?${link}`);
  }, [allPrimaryTickets]);

  const isReturnableSeasonalTicket = isSeasonalTicketReturnable(waznoscBiletuOd, ofertaKod, seasonalOffers);
  const downloadReservationClickHandler = () => {
    if (primaryTicket) {
      navigate('/rezerwacje');
      setDownloadedTicketData(primaryTicket);
    }
  };
  const {invalidateMyTicket} = useInvalidateMyTicketsQueries();

  const handleProgressModalClose = () => {
    navigate(profileMenuElements[invoiceError.length > 0 ? 1 : 4].url);
    window.scrollTo(0, 0);
    invalidateMyTicket(transakcjaNr, biletSeria, biletNr);
  };

  const isButtonActive = useIsTooLateToReturn(firstRoute.wyjazdData, true);

  return (
    <div className={style.mobile_ticket}>
      {!isManuallyAdded && !zwrotData && (
        <img src={`data:image/png;base64,${kod2D}`} className={style.qrcode} alt={t('29137')} />
      )}
      <div className={style.underqrCode}>
        <p className={style.text_blue}>{isReservationFromSeasonalTicket(biletRodzaj) ? t('29324') : t('29009')}</p>
        <p className={style.text_small}>
          {' '}
          {biletSeria} {biletNr.toString()}
        </p>
        <p className={style.text_small}>{kodKontrolny}</p>
      </div>
      {!isManuallyAdded && (
        <p className={style.text_small}>
          {isReservationFromSeasonalTicket(biletRodzaj)
            ? `${t('29482')} ${biletPodstawowySeria}${biletPodstawowyNr}`
            : t('29323')}
        </p>
      )}
      {!isManuallyAdded && (
        <div className={style.mobile_ticket__confirm_button_wrapper}>
          <ConfirmButton
            text={t('29025')}
            execute={() => {
              if (isTicketSourceEIC1(biletSeria)) goToOldVersion();
              else downloadPdf(biletSeria, biletNr);
            }}
          />
        </div>
      )}

      {!isSeasonal &&
        odcinki.map((el, index, arr) => {
          return (
            <div key={`${el.pociagNr}-${el.pociagNazwa}`} className={style.mobile_ticket__flex_column}>
              {arr.length > 1 && (
                <p className={style.text_big}>
                  {numberWordsArray[index]} {t('29291')}
                </p>
              )}
              <MyTicketsSingleTicketOpenMobileTripPart
                ticket={el}
                details={primaryAndAdditionalTickets}
                index={index}
                onCheckRouteClick={() => setIsRouteModalVisible(true)}
              />
              {index !== arr.length - 1 &&
                (!isInternationalEPA || (isInternationalEPA && ofertaKod !== OFFER_CODES.THERE_AND_BACK)) && (
                  <div className={style.addTicketContainer}>
                    <img
                      src={isContrastTheme ? ClockContrast : Clock}
                      alt="Zegar"
                      className={style.mobile_ticket__clock}
                    />
                    <p className={style.addticketText}>
                      {makeTimeOfTravel(el.przyjazdData, arr[index + 1].wyjazdData)} {t('17052')}
                    </p>
                  </div>
                )}
            </div>
          );
        })}

      {showChangedName && (
        <div className={style.singleTicket__passengerNameChangeContainer}>
          <p>
            {t('29245')}
            <button
              onClick={() => {
                downloadPdf(biletSeria, biletNr);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') downloadPdf(biletSeria, biletNr);
              }}
            >
              {t('29246')}
            </button>
          </p>
          <button
            onClick={() => {
              setShowChangedName(false);
            }}
            aria-label={t('29002')}
          >
            <img src={isContrastTheme ? ContrastX : WhiteX} alt={t('29002')} />
          </button>
        </div>
      )}

      {isSeasonal && (
        <div className={`${style.mobile_ticket__box}`}>
          <div className={`${style.mobile_ticket__box_textboxColumn}`}>
            <p className={`${style.text_small} ${style.tileHeader}`}> {isIntercityCard ? t('13083') : ofertaNazwa}</p>
            <div className={style.mobile_ticket__row}>
              <p className={style.text_blue}>{t('29322')}</p>
              <p className={style.text_small}>{getFullDate(waznoscBiletuOd!)}</p>
            </div>
            <div className={style.mobile_ticket__row}>
              <p className={style.text_blue}>{t('29321')}</p>
              <p className={style.text_small}>{getFullDate(waznoscBiletuDo!)}</p>
            </div>
          </div>
        </div>
      )}
      {isSeasonal && generateRoute()}
      <div className={`${style.mobile_ticket__box}`}>
        <div className={`${style.mobile_ticket__box_textbox}`}>
          <div className={style.travelerTile}>
            <p className={style.text_blue}>{t('29124')}</p>
            <p className={style.text_medium}>{isIntercityCard && podroznyNazwa === '' ? t('29382') : podroznyNazwa}</p>
          </div>
        </div>
      </div>

      <MyTicketSingleTicketOpenDetails primaryAndAdditionalTickets={primaryAndAdditionalTickets} />
      {/* International Additional Tickets */}
      {internationalEPAAdditionalTickets.map(({count, price, kind}) => (
        <MyTicketSingleTicketOpenAddTicket
          key={`${biletNr}-${biletSeria}`}
          quantity={count}
          cost={price}
          ticketKind={kind}
          isReturned={!!zwrotOpis}
          execute={() => {
            return;
          }}
        />
      ))}
      {/* Domestic Additional Tickets */}
      {additionalTickets.map((el) => (
        <MyTicketSingleTicketOpenAddTicket
          key={`${el.biletNr}-${el.biletSeria}`}
          quantity={el.podrozni.length}
          cost={el.cenaBilet}
          ticketKind={el.biletRodzaj}
          isReturned={!!el.zwrotOpis}
          execute={() => {
            return;
          }}
        />
      ))}
      {!isSeasonal && history === 0 && (
        <div className={style.mobile_ticket__calendar_feature}>
          <h3 className={style.text_medium}>{t('29320')}</h3>
          <div>
            <ICalendarLink
              filename={calendarEventFileName}
              event={calendarEvent}
              className={isContrastTheme ? style.calendar_icon_contrast : style.icalendar_icon}
            />
            <button
              aria-label={t('24070')}
              onClick={() =>
                addTripToCalendar(
                  returnStationByCode(firstRoute.stacjaOdKod, stationsList),
                  stationFromCode,
                  firstRoute.wyjazdData,
                  lastRoute.przyjazdData,
                  `${biletSeria}${biletNr}`,
                )
              }
            >
              <img src={Google} alt="Google" />
            </button>
          </div>
        </div>
      )}
      <div className={style.mobile_ticket__btns_box}>
        {!isManuallyAdded &&
          history !== 1 &&
          !checkIfTrainStarts(firstRoute.wyjazdData) &&
          (!isInternationalEPA || (isInternationalEPA && !!czyMozliwyZwrot)) && (
            <>
              <button
                className={`${style.mobile_ticket__btns_box_btn} ${isReturnableSeasonalTicket || !isButtonActive ? style.disabledBtn : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  if (isTicketSourceEIC1(biletSeria)) goToOldVersion();
                  else setOpenReturnTicket(true);
                }}
                disabled={isReturnableSeasonalTicket || !isButtonActive}
              >
                {t('29183')}
              </button>
              {openReturnTicket && ticketToReturn && (
                <ReturnTicketMobileFlow
                  tickets={ticketToReturn}
                  mainTicketIdentifier={identifier}
                  isSeasonal={isSeasonal}
                  closeFn={() => setOpenReturnTicket(false)}
                />
              )}
              {!isSeasonal && !isInternationalEPA && (
                <button
                  className={`${style.mobile_ticket__btns_box_btn} ${!isButtonActive ? style.disabledBtn : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (isTicketSourceEIC1(biletSeria)) goToOldVersion();
                    else setOpenExchangeTicket(true);
                  }}
                  disabled={!isButtonActive}
                >
                  {t('13148')}
                </button>
              )}
              {openExchangeTicket && ticketToReturn && (
                <ExchangeTicketModal
                  primaryTicket={primaryTicket}
                  tickets={ticketToReturn}
                  closeModal={() => setOpenExchangeTicket(false)}
                  exchangeReservation={isReservationFromSeasonalTicket(biletRodzaj)}
                />
              )}
            </>
          )}
        {history !== 1 &&
          new Date(lastRoute.przyjazdData).getTime() > new Date().getTime() &&
          identifier &&
          !isInternationalEPA && (
            <button
              className={style.mobile_ticket__btns_box_btn}
              onClick={(e) => {
                e.preventDefault();

                if (isTicketSourceEIC1(biletSeria)) goToOldVersion();
                else
                  navigate(
                    `/${makeQuery({
                      ticketInfo: identifier,
                      ticketDetail: {
                        bilety: primaryAndAdditionalTickets.filter(
                          (el) => el.biletRodzaj === 1 || el.biletRodzaj === 2 || el.biletRodzaj === 10,
                        ),
                        bledy: [],
                      },
                      searchDate: '',
                    })}`,
                  );
              }}
            >
              {t('13149')}
            </button>
          )}
        {!!zwrotOpis.length && identifier && (
          <button
            className={style.mobile_ticket__btns_box_btn}
            onClick={(e) => {
              e.preventDefault();

              if (isTicketSourceEIC1(biletSeria)) goToOldVersion();
              else
                navigate(
                  `/${isInternationalEPA ? 'polaczenia-miedzynarodowe' : ''}${makeQuery(
                    {
                      ticketInfo: identifier,
                      ticketDetail: {bilety: primaryAndAdditionalTickets, bledy: []},
                      searchDate: '',
                    },
                    true,
                  )}`,
                );
            }}
          >
            {t('29244')}
          </button>
        )}
        {!isManuallyAdded &&
          history !== 1 &&
          !checkIfTrainStarts(firstRoute.wyjazdData) &&
          mozliwaZmianaDanychPodroznego && (
            <>
              <button
                className={style.mobile_ticket__btns_box_btn}
                onClick={(e) => {
                  e.preventDefault();
                  if (isTicketSourceEIC1(biletSeria)) goToOldVersion();
                  else setOpenChangePassName(true);
                }}
              >
                {t('29247')}
              </button>
              {openChangePassName && primaryAndAdditionalTickets && (
                <ChangePassengerName
                  currentName={podroznyNazwa || ''}
                  ticket={primaryTicket}
                  isSeasonal={isSeasonal}
                  closeModal={() => setOpenChangePassName(false)}
                  onSuccessChange={(name) => {
                    if (podroznyNazwa !== name) {
                      const newTickets = primaryAndAdditionalTickets;
                      newTickets[0].podroznyNazwa = name;
                      setPrimaryAndAdditionalTickets(newTickets);
                      setShowChangedName(true);
                    }
                  }}
                />
              )}
            </>
          )}
        {!isManuallyAdded &&
          (hasInvoice ? (
            <span className={style.mobile_ticket__btns_bottom_border}>
              <button
                className={style.mobile_ticket__btns_box_btn}
                onClick={() => {
                  if (isTicketSourceEIC1(biletSeria)) goToOldVersion();
                  else handleInvoiceDownload(fakturaSeria, fakturaNr);
                }}
              >
                {t('29253')}
              </button>
            </span>
          ) : (
            checkInvoiceIssuable
          ))}
        {!history && isSeasonal && !isReturned && (
          <button onClick={downloadReservationClickHandler} className={style.mobile_ticket__btns_box_btn}>
            {t('29252')}
          </button>
        )}
        {allPrimaryTickets && !checkIfGuest() && !isSeasonalOfferCode(ofertaKod) && !isForeign && (
          <button onClick={handleCreateProfileClick} className={style.mobile_ticket__btns_box_btn}>
            {t('29024')}
          </button>
        )}

        {isManuallyAdded && (
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowRemoveTicketModal(true);
            }}
            className={style.mobile_ticket__btns_box_btn}
          >
            {t('29299')}
          </button>
        )}
      </div>
      {isRouteModalVisible && (
        <MyTicketsRouteModal
          routeData={routeData}
          trainCategory={firstRoute.pociagKategoriaNazwa}
          handleCloseModal={() => setIsRouteModalVisible(false)}
        />
      )}
      {isInvoiceInProgress && <InvoiceInProgressModal onClose={handleProgressModalClose} errorMessage={invoiceError} />}
      {ticketToReturn && showRemoveTicketModal && (
        <RemoveTicketModal
          ticketType={t('13103')}
          ticket={ticketToReturn[0]}
          onClose={() => setShowRemoveTicketModal(false)}
          exitFromDeletedTicketModal={exit}
        />
      )}
    </div>
  );
};

export default MyTicketsSingleTicketOpenMobile;
